.form-outer-box {
  height: 80vh;
  padding: 30px;
}
.form-row{
  justify-content: center;
  height: 100%;
  align-items: center;
}
.form-shadow{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 61px 0px rgba(0, 0, 0, 0.10);
  padding: 47px 55px;
   h4 {
    color: #272725;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.28px;
    margin: 0;
    margin-bottom: 34px;
    font-family: 'cera_promedium';
}
label {
  font-family: Poppins;
  color: #424147;
  font-size: 15px;
}
input{
  color: #6A6B6F;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #DCDCDE;
  height: 46px;
}
button{
  color: #FFF;
  font-family: 'cera_promedium';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 147.2%;
  letter-spacing: 0.22px;
  border-radius: 8px;
}
}
.ant-card  {
  position: relative;
}
.rangepicker {
  position: absolute;
  top: 8px;
  right: 12px;
}