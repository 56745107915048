.login-page {
  .login-form-content {
    .logo {
      width: 130px;
      height: 31px;
    }
    h2 {
      color: #02170f;
      text-align: left;
    }
    .ant-input {
      height: 48px;
    }
    .login-button {
      border-radius: 4px;
      background: #02170f;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 51px;
      &-forgetPass {
        height: 40px !important;
        &:hover {
          background: #02170f;
          color: #fff !important;
        }
      }
    }
    .log-in-salesforce {
      border-radius: 4px;
      color: #02170f;
      font-size: 16px;
      font-weight: 500;
      height: 57px;
      border-color: rgba(2, 23, 15, 0.08);
      justify-content: center;
    }
    .ant-divider {
      .ant-divider-inner-text {
        color: #02170f;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        text-transform: lowercase;
      }
      &:before {
        opacity: 0.1;
        background: var(--Kwipo-Dark-Green, #02170f);
      }
      &:after {
        opacity: 0.1;
        background: var(--Kwipo-Dark-Green, #02170f);
      }
    }
    .forget-password {
      color: #02170f;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin: 16px 0;
      display: block;
    }
  }
}
