.login-outer {
  font-family: "Poppins", sans-serif;
  .ant-row {
    margin: 0 !important;
    .ant-col {
      padding: 0 !important;
    }
  }
  .logo-head {
    padding: 20px 0px 0px 30px;
  }
  .login-details {
    width: 100%;
    height: calc(80% - 81px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:after {
      content: "";
      background-image: url("../images/half-circle1.png");
      width: 300px;
      height: 300px;
      position: absolute;
      top: 0;
      background-repeat: no-repeat;
      right: 100px;
      background-size: contain;
      @media (min-width: $tablet) and (max-width: $largeDesktop) {
        width: 35%;
        height: 35%;
      }
    }
    &:before {
      content: "";
      background-image: url("../images/half-circle2.png");
      width: 300px;
      height: 300px;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      left: 0;
      background-size: contain;
      @media (min-width: $tablet) and (max-width: $largeDesktop) {
        width: 35%;
        height: 35%;
      }
    }
    h2 {
      font-size: 30px;
      font-weight: 700;
      margin: 0;
      font-family: "Poppins", sans-serif;
    }
    p {
      color: #525252;
      font-size: 16px;
      font-weight: 400;
      margin: 16px 0;
      font-family: "Poppins", sans-serif;
    }
  }
  .banner-img {
    position: relative;
    background-image: url("../images/bg-img.jpg");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: $tablet) and (max-width: $largeDesktop) {
      background-image: url("../images/banner-bg.jpg");
    }
    .bg-img {
      width: 100%;
    }
  }
  .banner-content {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $tablet) {
      position: unset;
    }
    .banner-inner {
      background: #7f7d7d85;
      border: 1px solid #7f7d7d85;
      border-radius: 46px;
      width: 428px;
      margin-left: 60px;
      position: relative;
      height: 508px;
      @media (min-width: $tablet) and (max-width: $largeDesktop) {
        width: 60%;
        height: 350px;
        margin: 0;
      }
      h4 {
        width: 160px;
        color: white;
        font-size: 24px;
        padding-left: 28px;
        line-height: 50px;
        margin: 20px 0;
        font-family: "Poppins", sans-serif;
        @media (min-width: $tablet) and (max-width: $largeDesktop) {
          font-size: 18px;
          line-height: 38px;
        }
      }
      .thunderbolt {
        position: absolute;
        bottom: 76px;
        left: -38px;
      }
      .office-girl {
        margin-right: -68px;
        margin-left: auto;
        display: table;
        position: absolute;
        bottom: 0px;
        right: 19px;
        width: 75%;
      }
    }
  }
}

.login-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1677ff;
  color: rgb(255, 255, 255) !important;
  border: 1px solid #1677ff;

  img {
    width: 10%;
    margin-left: 5px;
  }
}

.login-form {
  padding: 0px 6rem;
  height: 100vh;
}

.form-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-img {
  padding-right: 6rem;
}

.login-form-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 25px 6px #0000000d;
  background-color: white;
  padding: 2rem;
  width: 320px;
  width: 400px;
  margin: 0 auto;
  border-radius: 12px;
  .ant-btn {
    height: 40px;
  }
  .ant-btn-primary {
    &:hover {
      background-color: #272725 !important;
    }
  }
  .ant-btn-default {
    display: flex;
    align-items: center;
    font-size: 16px;
    img {
      margin-right: 15px;
    }
    &:hover {
      border-color: #d9d9d9 !important;
      color: black !important;
    }
  }
  .already-account {
    color: #a1a1a1;
    margin-top: 10px;
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
  }
  h6 {
    font-size: 14px;
    color: #636363;
    margin: 10px 0;
  }
  .ant-divider {
    &::before,
    &::after {
      border-color: #bdbdbd;
    }
  }
}

.login-form-content .logo {
  margin-bottom: 30px;
}

.login-form-content input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
  height: 40px;
  font-size: 14px;
}
.ant-input-password {
  padding: 0px 11px;
}

.login-form-button {
  width: 100%;
  background-color: #009ddc;
  font-size: 16px;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
  line-height: 0;
  font-weight: 500;
  .btn-img {
    background: white;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    img {
      width: 28px;
      margin: 0;
    }
  }
}

.salesforce:hover {
  background-color: #009ddc !important;
}

.linkedin {
  background-color: #0076b0 !important;
}

.linkedin:hover {
  background-color: #0076b0 !important;
}

.login-form-content .chip {
  background-color: #272724;
}

.login-form-button {
  display: flex;
  align-items: center;
}

.login-form-button img {
  width: 10%;
  margin-left: 5px;
}

.googleLogin {
  margin-bottom: 0px;
}

.signUp {
  color: #459bd6;
  cursor: pointer;
}

.forget-password {
  color: blue;
  margin-top: 8px;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  &-error {
    flex-direction: row;
  }
}
