.template-modal {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  input {
    height: 50px !important;
  }
  .description,
  .title {
    padding: 10px;
    border-radius: 4px;
    margin: 0;
  }
  .title {
    border-radius: 4px;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .tempalte-profile-pic-box {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 15px #909090;
    background: white;
    min-height: 120px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .template-row {
    border-radius: 17px;
    border-radius: 17px;
    border: 6px solid #fff;
    padding: 40px;
    justify-content: center;
  }
  .template-profile-box {
    border-radius: 4px;
  }
  .tempalte-profile-pic {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    &.five-profile-pic {
      justify-content: space-between;
      .tempalte-profile-pic-box {
        width: 19%;
      }
    }
  }
}
.template-modal-outer {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #efefef;
  }
}

.edit-Modal-outer {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #ffffff;
  }
}

.preview {
  &-container {
    padding: 20px 40px;
    background-color: #ededed;
    border-radius: 17px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.25);
    h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }
  &-edit {
    h4 {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.21px;
      margin-bottom: 10px;
    }
  }
}

.background {
  &-container {
    display: flex;
    align-items: center;
  }
  &-item {
    padding: 10px 12px;
    font-size: 20px;
    border-radius: 4px;
    background-color: #ededed;
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
  }
}

.blur-slider {
  margin-top: 20px;
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #000000 !important;
  }
  .ant-slider-track {
    background-color: #000000;
  }
}

.blur-slider:hover {
  .ant-slider-track {
    background-color: #000000;
  }
}

.song-sub-title {
  font-size: 12px;
  color: #4c535f96;
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: 8px;
}

.song-list-row {
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0;
  justify-content: space-between;
  label {
    font-size: 13px;
    margin: 0;
    font-family: "cera_promedium";
  }
  input {
    height: 34px !important;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    width: 164px;
  }
  .song-list-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 13px;
      margin: 0;
      font-family: "cera_promedium";
    }
    .text-ellipse {
      max-width: 136px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.song-name {
  color: #272725;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #272725;
    font-size: 28px;
    font-weight: 700;
    font-family: "cera_probold";
  }
  .ant-btn {
    background: #3bb576;
    padding: 0;
    border-radius: 5px;
    border-color: #3bb576;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 4px;
    margin: 0 3px;
  }
}

.hype-song-dropdown {
  .hype-song-dropdown-input {
    user-select: none;
    border: 1px solid #e0e4ec;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 25px;
    padding: 8px;
    justify-content: space-between;

    .hype-song-dropdown-input-text {
      display: flex;
      align-items: center;
      img {
        margin-right: 0px;
      }
    }
  }
  .caret-down-icon {
    width: 30px;
    transition: all 0.3s ease-in-out;
  }

  .hype-song-menu-outer {
    padding: 0;
    transition: height 0.3s ease-in-out;
    transition-delay: 0.1s;
    overflow: hidden;
    width: 96%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    opacity: 1;
    transition: all 0.5s;
    height: max-content;
    visibility: visible;
    margin-top: 2px;
    position: absolute;
    z-index: 999;

    .upload-song {
      border-top: 1px solid #f0f0f0;
      font-size: 14px;
      font-weight: 400;
      color: black;
      position: sticky;
      bottom: 0;
      padding: 6px;
      bottom: 0;
      cursor: pointer;
      margin: 0 6px;
    }
  }
  .hype-song-menu {
    padding: 6px;
    background-color: rgb(255, 255, 255);
    height: 110px;
    overflow-y: scroll;
    .hype-song-menu-item {
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      img {
        margin-right: 10px;
      }
      &.active {
        background-color: #3bb576;
        color: white;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.overlay {
  position: fixed; /* Use fixed positioning */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
}
