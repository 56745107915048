.leaderboard {
  &-container {
    padding: 30px;
  }
}

.diabled-cal {
  position: absolute;
  top: 9px;
  right: 4px;
}
