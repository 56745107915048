.year-setting {
  .ant-select .ant-select-selector {
    padding: 7px 10px;
    height: unset;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
  }

  .ant-select .ant-select-selection-search {
    input {
      height: 44px !important;
    }
  }

  .select-menu__control {
    height: 46px;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
  }
  label {
    font-size: 16px;
    font-weight: bold;
    color: #454545;
    line-height: 25px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .ant-select {
    width: 100%;
  }
  .fiscal-year {
    display: flex;
    .ant-select {
      margin-right: 10px;
    }
  }
}

.session-setting {
  margin-top: 40px;
  label {
    font-size: 16px;
    font-weight: bold;
    color: #454545;
    line-height: 25px;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 1px;
  }
  span {
    font-size: 13px;
    color: #707070;
  }
  .ant-select .ant-select-selector {
    padding: 7px 10px;
    height: unset;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
  }
  .select-menu__control {
    height: 46px;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
  }

  .sessionTimeout {
    margin-top: 15px;
    display: flex;
    .ant-select {
      margin-right: 10px;
      width: 400px !important;
    }
  }
}

.setting-buttons {
  position: fixed;
  bottom: 20px;
  right: 90px;
}

.disclaimer {
  &-fiscal {
    font-size: 12px;
  }
}
