.reAuth {
  color: rgb(0, 136, 255);
}
.organisation-details {
  margin-top: 20px;
  .ant-collapse {
    background-color: white;
  }
  .ant-collapse-header-text {
    font-weight: 700;
    font-size: 14px;
  }
  .ant-collapse-content-box {
    p {
      span {
        font-weight: 700;
      }
    }
  }
}

.credit-management {
  margin-top: 32px;
  font-size: 20px;
  .metric {
    label {
      font-size: 18px;
      font-weight: 600;
      span {
        font-size: 13px;
        font-weight: 600;
      }
    }
    &-content {
      margin-top: 10px;
      font-size: 14px;
    }
    &-update_time {
      margin-left: 16px;
      .ant-input-number {
        width: 70px;
      }
    }
    &-sync_window {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      .ant-space-item {
        label {
          span {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: black;
        background-color: black;
      }
    }
    &-custom_time {
      display: flex;
      margin-top: 12px;
      .item {
        width: 220px;
        sup {
          color: red;
        }
        label {
          font-size: 14px;
        }
        &-content {
          margin-top: 5px;
          height: 38px;
          display: flex;
          .ant-select-selector {
            height: 38px;
          }
        }
        &-timezone {
          width: 350px;
        }
      }
    }
  }
}

.salesforce-timezone {
  .select-menu__control--is-disabled {
    background: rgb(235, 235, 235);
    cursor: not-allowed !important;
    border-color: #d9d9d9;
  }
}
