.unauthorised-page {
  height: 90vh;
  align-items: center;
  justify-content: center;
  h2 {
    color: #272725;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h3 {
    color: #7d7d7d;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
    margin: 0;
  }
  button {
    margin-top: 38px;
    border-radius: 5px;
    background: #272725;
    min-width: 240px;
    padding: 10px;
  }
}
