.cmn-table {
  .ant-table {
    height: calc(76vh - 50px);
    border: 1px solid #b7b7b7;
    border-radius: 8px;
    font-family: "cera_promedium";
    font-size: 16px;
  }
  .ant-table-placeholder {
    height: calc(65vh - 20px);
    .ant-table-cell {
      border-bottom: none;
    }
  }
  .ant-table-thead {
    tr th {
      background-color: #000;
      color: rgb(255, 255, 255);
      border-right: 1px solid #ffffff;
      &::before {
        content: unset !important;
      }
      .ant-table-column-sorter {
        color: #ffffff;
      }
      &.ant-table-column-has-sorters {
        background-color: #000;
        &:hover {
          background-color: #000;
          .ant-table-column-sorter {
            color: #ffffff;
          }
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .ant-table-body {
    margin-right: -6px;
  }
  .ant-table-tbody {
    tr {
      td {
        border-right: 1px solid #b7b7b7;
        border-bottom: 1px solid #b7b7b7;
        color: $theme-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .ant-pagination {
    li {
      border: 1px solid #dfe3e8;
      a {
        color: $theme-color;
      }
      &.ant-pagination-item-active {
        border-color: #272725;
        a {
          color: $theme-color;
        }
      }
    }
  }
}

.status {
  border-radius: 20px;
  text-align: center;
  padding: 4px;
  &-active {
    background: $theme-color;
    color: #fff;
  }
  &-inactive {
    background: rgb(128, 128, 128) !important;
    color: #fff;
  }
}

.dot {
  width: 12px;
  height: 12px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  &-yellow {
    background: #ff9a00;
  }
}

.groups {
  .ant-table {
    height: calc(51vh - 38px);
  }
}

.salesforce-notification {
  .ant-table {
    height: calc(51vh - 38px);
  }
}
