.service {
  &-container {
    padding: 10px 30px 0px 40px;
  }
  &-heading {
    font-size: 16px;
  }
  &-label {
    font-weight: 800;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  &-list {
    margin-top: 2px;
  }
}
