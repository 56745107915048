.tooltip {
  &-container {
    margin: 0px;
    list-style-type: none;
    padding: 0px;

    &-list {
      list-style-type: none;
      padding: 0px;
      margin: 12px 0px;
    }
  }

  &-title {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }

  &-marker {
    background: rgb(91, 143, 249);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }

  &-value {
    display: inline-block;
    float: right;
    margin-left: 30px;
  }
}
