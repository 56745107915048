.ant-card {
  .ant-card-body {
    border-radius: 0 0 6px 6px;
    margin-bottom: 16px;
    padding: 5px 24px;
    .connected-user {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.6px;
      color: #242424;
      align-items: center;
      .user-count {
        width: 45px;
        height: 21px;
        border-radius: 2px;
        border: 0.6px solid #000;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .sub {
        font-size: 8px;
      }
    }
  }
  .ant-card-head {
    background: #000;
    color: white;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 6px 6px 0 0;
  }
}
.list-tabs {
  padding: 20px 0px;
  border-radius: 6px;
  margin-bottom: 15px;
  .menu-item {
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.18px;
    padding: 7px 14px;
    margin-right: 18px;
    cursor: pointer;
    background-color: rgba(2, 23, 15, 0.08);
    &.active {
      background-color: #000;
      color: #fff;
    }
  }
  .custom-filter {
    width: 38%;
    margin-top: 25px;
    .ant-picker {
      width: 65%;
      height: 32px;
    }
    .apply {
      font-size: 14px;
      font-weight: 500;
      margin-right: 23px;
      height: 32px;
      cursor: pointer;
    }
  }
}

.access-count {
  background: white;
  border-radius: 10px;
  padding: 2px 10px;
  margin-right: 16px;
  box-shadow: rgba(5, 9, 42, 0.1) 13px 19px 11px -12px inset,
    rgba(0, 0, 0, 0.1) 14px 18px 36px 21px inset;
  .ant-statistic-title {
    margin-right: 8px;
    margin-bottom: 0px;
  }
}

.horizontal-line {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
}

.configure {
  color: #0095ff;
}

.multi-select-box {
  background: #f0f0f0;
  padding: 6px;
  border-radius: 7px;
}

.upload-outer {
  display: flex;
  justify-content: space-between;
  border: 1px dotted #3f3f3f;
  border-width: 3px;
  border-spacing: revert;
  border-radius: 14px;
  padding: 56px 30px;
  align-items: center;
  margin-bottom: 10px;
  .drag-here {
    text-align: center;
  }
  h5 {
    font-size: 18px;
    font-weight: 700;
    font-family: "cera_probold";
    margin: 0;
    color: #272725;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    font-family: "cera_promedium";
    margin: 0;
    color: #272725;
  }
  span {
    color: #878787;
    font-family: "cera_promedium";
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn {
  border: 2px solid #272725;
  color: gray;
  background-color: white;
  padding: 14px 20px;
  border-radius: 26px;
  font-size: 20px;
  font-weight: bold;
  background-color: #272725;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 200px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.img {
  &-container {
    width: 400px;
    height: 400px;
    overflow: hidden;
    position: relative;
    cursor: grab;
    border: 1px solid black;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-zoom {
    position: absolute;
  }
  &-shadow {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      box-shadow: inset 0 0 0 100px var(--slds-g-color-neutral-base-100, white),
        0 0 0 103px var(--slds-g-color-neutral-base-100, white),
        inset 0 0 0 50px var(--slds-g-color-neutral-base-100, white),
        0 0 0 103px var(--slds-g-color-neutral-base-100, white);
      border-radius: 290px;
    }
  }
  &-crop {
    height: 200px;
    width: 200px;
  }
}

.small-icon {
  position: absolute;
  left: 9px;
  svg {
    height: 13px !important;
    width: 13px !important;
  }
}

.big-icon {
  position: absolute;
  top: 0;
  right: 0;
  svg {
    height: 18px !important;
    width: 18px !important;
  }
}

.adjustment-slider {
  width: 84%;
  margin: 11px auto;
}

.display_channels {
  margin-top: 10px;
  span {
    font-weight: 700;
  }
  label span {
    font-weight: 500 !important;
  }
}
