.tabs {
  position: relative;
  .ant-tabs-nav {
    &::before{
      border: none;
    }
  }
  .ant-tabs-tab {
    font-size: 28px;
    color: #b0b0b0;
    background: transparent !important;
    border: transparent !important;
    &:hover{
      color: #000 !important;
    }
    &.ant-tabs-tab-active{
    .ant-tabs-tab-btn {
      color: #000;
    }
    }
  }
  .filter {
    position: absolute;
    right: 0;
    top: -60px;
  }
}